<!-- 认证信息填写 -->
<template>
  <div class="authentication-container">
    <van-form @submit="next" :show-error-message="false">
      <div class="steps-container">
        <van-steps :active="active" active-color="#FE8A3D">
          <van-step>填写信息</van-step>
          <van-step>人脸验证</van-step>
          <van-step>认证成功</van-step>
        </van-steps>
      </div>
      <div class="authentication-info">
        <h4 class="title">
          <p>请填写实名认证信息</p>
        </h4>
        <div class="content">
          <van-field
            v-model="info.name"
            :rules="[{ required: true, message: '请输入姓名' }]"
            input-align="right"
            label="姓名"
            placeholder="请输入姓名"
          />
          <van-field
            v-model="info.cardNo"
            :rules="[
              { required: true, message: '请输入证件号码' },
              { validator: validator.idCard, message: '身份证号码格式错误！' }
            ]"
            input-align="right"
            label="证件号码"
            placeholder="请输入证件号码"
            maxlength="18"
          />
          <van-field
            v-model="info.mobile"
            :rules="[
              { required: true, message: '请输入手机号码' },
              { validator: validator.phone, message: '手机号码格式错误！' }
            ]"
            type="tel"
            label="手机号"
            input-align="right"
            placeholder="请输入手机号码"
            maxlength="11"
          />
          <van-field
            v-model="info.code"
            :rules="[{ required: true, message: '请输入验证码' }]"
            input-align="right"
            label="验证码"
            placeholder="请输入验证码"
          >
            <template #button>
              <p @click="getVerificationCode" class="verification-code">
                {{ codeText }}
              </p>
            </template>
          </van-field>
        </div>
      </div>
      <div class="next">
        <button class="next-btn" type="submit">下一步</button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { certify, sendCode } from '../../api/api'
import { setSession, getSession, validator } from '../../util/util'

export default {
  data () {
    return {
      active: 0,
      value: '',
      info: {
        name: '',
        cardNo: '',
        mobile: '',
        code: ''
      },
      time: 0,
      validator: validator,
      codeText: '获取验证码',
      timeId: ''
    }
  },
  methods: {
    async next () {
      const toast = this.$toast.loading({
        overlay: true,
        forbidClick: true,
        message: '认证中，请稍后...',
        duration: 0
      })
      await certify(this.info)
      toast.clear()
      let info = getSession('policyToolsInfo')
      let { name, cardNo, mobile } = this.info
      info.name = name
      info.cardNo = cardNo
      info.mobile = mobile
      info.step = 1
      setSession('policyToolsInfo', info)
      this.$router.push('/faceCheck')
    },
    async getVerificationCode () {
      if (!this.info.mobile) {
        this.$toast('请先填写手机号!')
        return
      }
      if (this.time != 0) {
        return
      }

      const toast = this.$toast.loading({
        overlay: true,
        forbidClick: true,
        message: '发送中，请稍后...',
        duration: 0
      })

      await sendCode({ mobile: this.info.mobile })
      toast.clear()
      this.$toast('发送成功，请注意查收！')
      this.time = 60
      this.timeId = setInterval(() => {
        this.time--
        this.codeText = `${this.time}s`
        if (this.time == 0) {
          this.codeText = '获取验证码'
          clearInterval(this.timeId)
        }
      }, 1000)
    }
  },
  destroyed () {
    clearInterval(this.timeId)
  }
}
</script>
<style lang="less" scoped>
.authentication-container {
  padding: 40px 24px 0;
  .steps-container {
    width: 702px;
    margin: 0 auto;
  }
  .authentication-info {
    margin-top: 40px;
    .title {
      position: relative;
      min-width: 288px;
      height: 45px;
      font-size: 32px;
      font-weight: 600;
      color: #333333;
      line-height: 45px;
      p {
        position: absolute;
        z-index: 3;
      }
      &::after {
        content: '';
        position: absolute;
        left: 160px;
        bottom: 0;
        width: 128px;
        height: 16px;
        background: linear-gradient(
          90deg,
          rgba(255, 234, 209, 0.34) 0%,
          #fed1a4 100%
        );
        z-index: 2;
      }
    }
    .content {
      margin-top: 20px;
      padding: 10px;
      border-radius: 30px;
      box-shadow: 0px 4px 77px 0px rgba(0, 0, 0, 0.08);
      .verification-code {
        position: relative;
        padding-left: 24px;
        color: #ff831c;
        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 4px;
          width: 2px;
          height: 42px;
          background-color: #dddddd;
        }
      }
      /deep/.van-field__button {
        padding-left: 24px;
      }
      .van-cell {
        padding: 20px 14px;
        .van-cell__title {
          color: #777;
        }
      }
      .van-cell::after {
        border-color: #f2f2f2;
      }
    }
  }
  .next {
    display: flex;
    justify-content: center;
    margin-top: 100px;
    .next-btn {
      width: 619px;
      height: 92px;
      font-size: 32px;
      color: #fff;
      text-align: center;
      line-height: 92px;
      background: linear-gradient(270deg, #ffbe70 0%, #fe8a3d 100%);
      box-shadow: 0px 6px 0px 0px rgba(253, 154, 77, 0.19);
      border-radius: 46px;
    }
  }
}
</style>
